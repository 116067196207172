// base functions and mfw service object for CRUD operations on MFW policies and appliance settings

import { accessDefaultRules, filterDefaultRules, wanDefaultRules, shapingDefaultRules } from 'vuntangle'
import cloneDeep from 'lodash/cloneDeep'
import store from '@/store'
import api from '@/plugins/ut/ut-api'

// types used for Micro Edge Templates , prefixed with `mfw-template`
export const TemplateType = Object.freeze({
  // services
  TemplateApplicationControl: 'mfw-template-applicationcontrol',
  TemplateCaptivePortal: 'mfw-template-captiveportal',
  TemplateGeoipFilter: 'mfw-template-geoipfilter',
  TemplateNetworkDiscovery: 'mfw-template-networkdiscovery',
  TemplateThreatPrevention: 'mfw-template-threatprevention',
  TemplateWebFilter: 'mfw-template-webfilter',
  TemplateDynamicBlocklist: 'mfw-template-dynamicblocklist',
  TemplateDenialOfService: 'mfw-template-denialofservice',

  // non-service
  TemplateDhcp: 'mfw-template-dhcp',
  TemplateDns: 'mfw-template-dns',
  TemplateStaticRoutes: 'mfw-template-staticroutes',
  TemplateWanPolicy: 'mfw-template-wanpolicy',

  // rules
  TemplateRuleFilter: 'mfw-template-rule-filter',
  TemplateRuleFilterGroup: 'mfw-template-rule-filtergroup',
  TemplateRuleWan: 'mfw-template-rule-wan',
  TemplateRulePortForward: 'mfw-template-rule-portforward',
  TemplateRuleNat: 'mfw-template-rule-nat',
  TemplateRuleShaping: 'mfw-template-rule-shaping',
  TemplateRuleAccess: 'mfw-template-rule-access',
  TemplateStatusAnalyzers: 'mfw-template-status-analyzers',
})

/**
 * the policy/template types, used for mfwServices and settingsComponent
 */
export const ServiceName = Object.freeze({
  WebFilter: 'web-filter',
  ThreatPrevention: 'threat-prevention',
  GeoipFilter: 'geoip-filter',
  NetworkDiscovery: 'network-discovery',
  WanPolicy: 'wan-policies',
  ApplicationControl: 'application-control',
  DenialOfService: 'denial-of-service',
  Dns: 'dns',
  WanRule: 'wan-rules',
  Filter: 'filter',
  FilterGroup: 'filter-groups',
  Access: 'access',
  Shaping: 'shaping',
  Dhcp: 'dhcp',
  CaptivePortal: 'captive-portal',
  PortForward: 'port-forward',
  Nat: 'nat',
  StatusAnalyzers: 'status-analyzers',
  StaticRoutes: 'static-routes',
  Interfaces: 'interfaces',
  InterfaceTrackers: 'interface-trackers',
  DynamicBlocklist: 'dynamic-blocklist',
  ApplianceLogging: 'appliance-logging',
  ApplianceSystem: 'appliance-system',
  Database: 'database',
  DnsFilter: 'dns-filter',
})

// used in mfwServices to denote the componentType
export const ComponentType = Object.freeze({
  Service: 'service',
  Rule: 'rule',
  Group: 'group',
  System: 'system',
})

// used in mfwServices to denote the sync action
export const SyncAction = Object.freeze({
  None: 'none',
  Single: 'single',
  Multiple: 'multiple',
})

/**
 * mfw policies navigation categories
 */
export const mfwTemplatesNavCategories = [
  {
    tkey: 'network',
    cat: 'network',
    to: { name: 'mfw-policies-type', params: { category: 'network' } },
    icon: 'mdi-web',
    subItems: [],
  },
  {
    tkey: 'routing',
    cat: 'routing',
    to: { name: 'mfw-policies-type', params: { category: 'routing' } },
    icon: 'mdi-call-split',
    subItems: [],
  },
  {
    tkey: 'firewall',
    cat: 'firewall',
    to: { name: 'mfw-policies-types', params: { category: 'firewall' } },
    icon: 'mdi-shield-half-full',
    subItems: [],
  },
  {
    tkey: 'services',
    cat: 'services',
    to: { name: 'mfw-policies-types', params: { category: 'services' } },
    icon: 'mdi-apps',
    subItems: [],
  },
  {
    tkey: 'system',
    cat: 'system',
    to: { name: 'mfw-policies-types', params: { category: 'system' } },
    icon: 'mdi-apps',
    subItems: [],
  },
]

/**
 * mfw policies object
 *
 * key: common key name from "ServiceName", mainly passed through the router
 * type: the policy type stored in cloud services
 * category: category in which the object appears in drop down menu
 * componentType: type in ComponentType const, used as a grouping of logical service types that have similar function
 * version: lowest version compatible with policies
 * tkey: translation key
 * bkey: backend store settings key
 * defaultRules: component for the default rules used in mfw
 * settingPathForBox: path in the box to save rules to (in settings.json)
 * securityLicenseRequired: Boolean if the service requires a security license
 * componentName: The vue component name in vuntangle, used to display the component when editing the service
 * canBeTemplate:  used to show the service in the mfw templates area, not all services are available as templates
 * syncAction: Optional, Only required if the service can be template, determines what type of sync action can be done
 * chainName:  Optional, currently in AppliancesRules.vue, need the chain name of some rules services
 */
export const mfwServices = {
  [ServiceName.WebFilter]: {
    type: TemplateType.TemplateWebFilter,
    category: 'services',
    componentType: ComponentType.Service,
    version: 5.0,
    tkey: 'web_filter',
    bkey: 'WebFilter',
    defaultRules: [],
    settingPathForBox: 'webfilter',
    securityLicenseRequired: true,
    componentName: 'SettingsWebFilter',
    canBeTemplate: true,
    syncAction: SyncAction.Single,
    showCacheClear: true,
  },
  [ServiceName.ThreatPrevention]: {
    type: TemplateType.TemplateThreatPrevention,
    category: 'services',
    componentType: ComponentType.Service,
    version: 5.0,
    tkey: 'threat_prevention',
    bkey: 'ThreatPrevention',
    defaultRules: [],
    settingPathForBox: 'threatprevention',
    securityLicenseRequired: true,
    componentName: 'SettingsThreatPrevention',
    canBeTemplate: true,
    syncAction: SyncAction.Single,
    showCacheClear: true,
  },
  [ServiceName.GeoipFilter]: {
    type: TemplateType.TemplateGeoipFilter,
    category: 'services',
    componentType: ComponentType.Service,
    version: 5.0,
    tkey: 'geoip_filter',
    bkey: 'GeoipFilter',
    defaultRules: [],
    settingPathForBox: 'geoip',
    securityLicenseRequired: true,
    componentName: 'SettingsGeoipFilter',
    canBeTemplate: true,
    syncAction: SyncAction.Single,
  },
  [ServiceName.NetworkDiscovery]: {
    type: TemplateType.TemplateNetworkDiscovery,
    category: 'services',
    componentType: ComponentType.Service,
    version: 5.0,
    tkey: 'network_discovery',
    bkey: 'NetworkDiscovery',
    defaultRules: [],
    settingPathForBox: 'discovery',
    securityLicenseRequired: true,
    componentName: 'SettingsNetworkDiscovery',
    canBeTemplate: true,
    syncAction: SyncAction.Single,
  },
  [ServiceName.WanPolicy]: {
    type: TemplateType.TemplateWanPolicy,
    category: 'routing',
    componentType: ComponentType.Service,
    tkey: 'wan_policies',
    bkey: 'WanPolicies',
    defaultRules: [],
    settingPathForBox: 'wanpolicies',
    securityLicenseRequired: false,
    componentName: 'WanPolicy',
    canBeTemplate: true,
    syncAction: SyncAction.None,
  },
  [ServiceName.ApplicationControl]: {
    type: TemplateType.TemplateApplicationControl,
    category: 'services',
    componentType: ComponentType.Service,
    version: 5.0,
    tkey: 'application_control',
    bkey: 'AppControl',
    defaultRules: [],
    settingPathForBox: 'application_control',
    securityLicenseRequired: false,
    componentName: 'SettingsApplicationControl',
    canBeTemplate: true,
    syncAction: SyncAction.Single,
  },
  [ServiceName.DenialOfService]: {
    type: TemplateType.TemplateDenialOfService,
    category: 'services',
    componentType: ComponentType.Service,
    version: 6.1,
    tkey: 'denial_of_service',
    settingPathForBox: 'denial_of_service',
    securityLicenseRequired: false,
    componentName: 'SettingsDenialOfService',
    canBeTemplate: true,
    // syncAction: SyncAction.Single,
  },
  [ServiceName.Dns]: {
    type: TemplateType.TemplateDns,
    category: 'network',
    componentType: ComponentType.Service,
    version: 5.0,
    tkey: 'dns',
    bkey: 'Dns',
    defaultRules: [],
    settingPathForBox: 'dns',
    securityLicenseRequired: false,
    componentName: 'Dns',
    canBeTemplate: true,
    syncAction: SyncAction.Single,
  },
  [ServiceName.WanRule]: {
    type: TemplateType.TemplateRuleWan,
    category: 'routing',
    componentType: ComponentType.Rule,
    version: 5.0,
    tkey: 'wan_rules',
    bkey: 'WanRules',
    defaultRules: wanDefaultRules,
    settingPathForBox: 'wan',
    securityLicenseRequired: false,
    canBeTemplate: true,
    syncAction: SyncAction.Multiple,
    chainName: 'user-wan-rules',
  },
  [ServiceName.Filter]: {
    type: TemplateType.TemplateRuleFilter,
    category: 'firewall',
    componentType: ComponentType.Rule,
    version: 5.0,
    tkey: 'filter_rules',
    bkey: 'FilterRules',
    defaultRules: filterDefaultRules,
    settingPathForBox: 'firewall/tables/filter',
    securityLicenseRequired: false,
    canBeTemplate: true,
    syncAction: SyncAction.Multiple,
    chainName: 'filter-rules',
  },
  [ServiceName.FilterGroup]: {
    type: TemplateType.TemplateRuleFilterGroup,
    category: 'firewall',
    componentType: ComponentType.Group,
    version: 5.0,
    tkey: 'filter_groups',
    bkey: 'FilterRuleGroups',
    defaultRules: filterDefaultRules,
    securityLicenseRequired: false,
    canBeTemplate: true,
    syncAction: SyncAction.None,
  },
  [ServiceName.Access]: {
    type: TemplateType.TemplateRuleAccess,
    category: 'firewall',
    componentType: ComponentType.Rule,
    version: 5.0,
    tkey: 'access_rules',
    bkey: 'AccessRules',
    defaultRules: accessDefaultRules,
    settingPathForBox: 'firewall/tables/access',
    securityLicenseRequired: false,
    canBeTemplate: true,
    syncAction: SyncAction.Multiple,
    chainName: 'access-rules',
  },
  [ServiceName.Shaping]: {
    type: TemplateType.TemplateRuleShaping,
    category: 'network',
    componentType: ComponentType.Rule,
    version: 5.0,
    tkey: 'shaping_rules',
    bkey: 'ShapingRules',
    defaultRules: shapingDefaultRules,
    settingPathForBox: 'firewall/tables/shaping',
    securityLicenseRequired: false,
    canBeTemplate: true,
    syncAction: SyncAction.Multiple,
    chainName: 'prioritization-rules',
  },
  [ServiceName.Dhcp]: {
    type: TemplateType.TemplateDhcp,
    category: 'network',
    componentType: ComponentType.Rule,
    version: 5.0,
    tkey: 'dhcp',
    settingPathForBox: 'dhcp',
    securityLicenseRequired: false,
    componentName: 'Dhcp',
    canBeTemplate: false,
  },
  [ServiceName.CaptivePortal]: {
    type: TemplateType.TemplateCaptivePortal,
    category: 'services',
    componentType: ComponentType.Service,
    version: 5.1,
    tkey: 'captive_portal',
    settingPathForBox: 'captiveportal',
    securityLicenseRequired: true,
    componentName: 'SettingsCaptivePortal',
    canBeTemplate: false,
    syncAction: SyncAction.Single,
  },
  [ServiceName.PortForward]: {
    type: TemplateType.TemplateRulePortForward,
    category: 'network',
    componentType: ComponentType.Rule,
    version: 5.0,
    tkey: 'port_forward',
    settingPathForBox: 'firewall/tables/port-forward',
    securityLicenseRequired: false,
    canBeTemplate: false,
    chainName: 'port-forward-rules',
  },
  [ServiceName.Nat]: {
    type: TemplateType.TemplateRuleNat,
    category: 'network',
    componentType: ComponentType.Rule,
    version: 5.0,
    tkey: 'nat',
    settingPathForBox: 'firewall/tables/nat',
    securityLicenseRequired: false,
    canBeTemplate: false,
    chainName: 'nat-rules',
  },
  [ServiceName.StaticRoutes]: {
    type: TemplateType.TemplateStaticRoutes,
    category: 'network',
    componentType: ComponentType.Service,
    version: 5.0,
    tkey: 'static_routes',
    settingPathForBox: 'routes',
    componentName: 'StaticRoutes',
    securityLicenseRequired: false,
    canBeTemplate: false,
  },
  [ServiceName.StatusAnalyzers]: {
    type: TemplateType.TemplateStatusAnalyzers,
    category: 'network',
    componentType: ComponentType.Service,
    version: 5.0,
    tkey: 'status_analyzers',
    settingPathForBox: 'stats/pingAnalyzers',
    componentName: 'ApplianceAnalyzers',
    securityLicenseRequired: false,
    canBeTemplate: true,
  },
  [ServiceName.InterfaceTrackers]: {
    category: 'network',
    componentType: ComponentType.Service,
    version: 5.0,
    tkey: 'interface_trackers',
    settingPathForBox: 'network/track',
    componentName: 'ApplianceAnalyzers',
    securityLicenseRequired: false,
    canBeTemplate: false,
  },
  [ServiceName.Interfaces]: {
    category: 'network',
    componentType: ComponentType.Service,
    version: 1.0,
    tkey: 'interfaces',
    settingPathForBox: 'network/interfaces',
    componentName: 'ApplianceInterfaces',
    securityLicenseRequired: false,
    canBeTemplate: false,
  },
  [ServiceName.DynamicBlocklist]: {
    type: TemplateType.TemplateDynamicBlocklist,
    category: 'services',
    componentType: ComponentType.Service,
    version: 5.1,
    tkey: 'dynamic_blocklist',
    settingPathForBox: 'dynamic_lists',
    componentName: 'ApplianceDynamicLists',
    securityLicenseRequired: false,
    canBeTemplate: false,
  },
  [ServiceName.ApplianceLogging]: {
    category: 'system',
    version: 5.1,
    tkey: 'logging',
    componentName: 'ApplianceLogging',
    securityLicenseRequired: false,
    canBeTemplate: false,
  },
  [ServiceName.ApplianceSystem]: {
    category: 'system',
    version: 5.0,
    tkey: 'settings',
    componentName: 'ApplianceSystem',
    securityLicenseRequired: false,
    canBeTemplate: false,
  },
  [ServiceName.Database]: {
    category: 'system',
    version: 6.1,
    tkey: 'database',
    settingPathForBox: 'databases',
    componentName: 'Database',
    securityLicenseRequired: true,
    canBeTemplate: false,
  },
  [ServiceName.DnsFilter]: {
    category: 'services',
    componentType: ComponentType.Service,
    version: 6.1,
    tkey: 'dns_filter',
    bkey: 'DnsFilter',
    defaultRules: [],
    settingPathForBox: 'dns_filter',
    securityLicenseRequired: true,
    componentName: 'SettingsDnsFilter',
  },
}

/**
 * looks through each policy to match type and returns the key of the policy
 *
 * @param type
 * @returns policyKey
 */
export function getKeyFromPolicyType(type) {
  return Object.keys(mfwServices).find(key => mfwServices[key].type === type)
}

/**
 * looks through each policy to match routePath and returns the key of the policy
 *
 * @param componentType - type of component, rule or service for now
 * @returns policyTypes string with all policy name times in format (poliy1|policy2|...)
 */
export function getPolicyRouteRegex(componentType) {
  return (
    '(' +
    Object.keys(mfwServices)
      .filter(key => mfwServices[key].componentType === componentType && mfwServices[key].canBeTemplate)
      .join('|') +
    ')'
  )
}

/**
 * builds the navigation items object using the mfwPoliciesNavCategories and mfwPolicies object above
 *
 * @returns mfwPoliciesNavItems object containing navigation information for all policies in the mfw policies bar
 */
export function getPoliciesNavItems() {
  // make a deep copy of mfwPoliciesNavCategories so we don't actually change mfwPoliciesNavCategories itself
  const mfwTemplatesNavItems = cloneDeep(mfwTemplatesNavCategories)
  // add all policies into the subitems of the corresponding category
  Object.entries(mfwServices).forEach(policy => {
    const networkObj = mfwTemplatesNavItems.find(obj => obj.cat === policy[1].category)
    if (networkObj && store.state.data.mfwPoliciesOptions[policy[1].bkey]) {
      networkObj.subItems.push({
        tkey: policy[1].tkey,
        bkey: policy[1].bkey,
        to: {
          name: 'mfw-policies-types',
          params: { policyPage: policy[0] },
        },
      })
    }
  })

  // if there are no subitems in a category do not include that category
  return mfwTemplatesNavItems.filter(category => category.subItems.length !== 0)
}

/**
 * return the route to policyManger if user has it enabled, or the first item in the navigation menu
 *
 * @return String
 */
export function getDefaultPolicyPage() {
  const policyPage = {
    name: 'mfw-policies-types',
    params: { policyPage: store.state.data.mfwPoliciesOptions.FirstEnabled },
  }

  const navItems = getPoliciesNavItems()
  for (const category of navItems) {
    if (category.subItems.length !== 0) {
      policyPage.params.policyPage = category.subItems[0].to.params.policyPage
      return policyPage
    }
  }
}

/**
 * single method for add/update crud op
 */
export function savePolicy(cloudPolicy, policyType) {
  const requestObj = {
    name: cloudPolicy.Name,
    description: cloudPolicy.Description,
    type: policyType,
    policyJson: JSON.stringify(cloudPolicy.PolicyJson),
    paramOrder: 'name description type version policyJson',
  }

  let action = 'CreatePolicy'
  if (cloudPolicy.Id) {
    action = 'UpdatePolicy'
    requestObj.policyId = cloudPolicy.Id
    requestObj.paramOrder = 'policyId name description type policyJson'
  }

  return api.cloud('Untangle_CommandCenter', action, requestObj)
}

/**
 * Get rule's details based on policy id
 *
 * @param policyId
 * @returns {Promise<*>}
 */
export function getPolicy(policyId) {
  return api.cloud('Untangle_CommandCenter', 'GetPolicy', {
    policyId,
    paramOrder: 'policyId',
  })
}

/**
 * checks if any of the given subscription is security edition
 * @param subscriptions - array of subscriptions
 * @return boolean      - true if security edition subscription
 */
function validSubscription(subscriptions) {
  for (let i = 0; i < subscriptions.length; i++) {
    const sub = store.state.subscriptions.list.find(sub => sub.subscriptionName === subscriptions[i].subscriptionName)
    if (sub.sku.includes('SWSE')) {
      return true
    }
  }

  return false
}

/**
 * check if appliance is compatible with service. Appliance must be mfw, meet the minimum required software
 * version, be licensed for command center, have security subscription if policy requires
 *
 * @param {String}  serviceKey  serviceKey from the mfwServices object
 *
 * @return {Object[]}
 */
export function getFilteredAppliancesByService(serviceKey) {
  const filteredAppliances = []
  const applianceList = store.state.appliances.list
  if (applianceList === null) {
    return filteredAppliances
  }

  for (const appliance of applianceList) {
    // if security license is required for this policy, ensure the appliance has one. if the account doesn't need license, return true
    const validSub = store.state.data.ccViewModel.Account.NoLicenseEnforcement
      ? true
      : mfwServices[serviceKey].securityLicenseRequired
      ? validSubscription(appliance.Subscriptions)
      : true
    if (
      appliance.ProductLine === 'MFW' &&
      (appliance.IsLicensedForCommandCenter || store.state.data.ccViewModel.Account.NoLicenseEnforcement) &&
      parseFloat(appliance.SoftwareVersion) >= mfwServices[serviceKey].version &&
      validSub
    ) {
      filteredAppliances.push(appliance)
    }
  }

  return filteredAppliances
}
