<template>
  <div class="fill-height d-flex flex-column">
    <grid-wrapper
      ref="wrapper"
      :selection.sync="selectedTasks"
      :permanent-delete="false"
      @delete="onDelete(selectedTasks)"
    >
      <template #default="{ selectionType }">
        <!-- tasks grid -->
        <u-grid
          id="tasks-grid-widget"
          :selection-type="selectionType"
          row-node-id="TaskId"
          :no-data-message="noDataMessage"
          :column-defs="tasksColumnDefs"
          :fetching="fetching"
          :row-data="taskItems"
          :row-actions="rowActions"
          :selection.sync="selectedTasks"
          @refresh="getTaskList()"
        >
        </u-grid>

        <!-- selected task error if exists -->
        <v-card
          v-if="selectedTask && selectedTask.ErrorMessage && selectionType !== 'multiAction'"
          outlined
          class="mt-2"
        >
          <v-card-title class="text-body-1 error--text font-weight-bold">{{ $t('error') }}</v-card-title>
          <v-card-text>
            <span>{{ $t(selectedTask.ErrorMessage) }}</span>
          </v-card-text>
        </v-card>
      </template>
    </grid-wrapper>
  </div>
</template>

<script>
  import GridWrapper from '../../pages/policies/mfw/policy-manager/components/GridWrapper.vue'
  import api from '@/plugins/ut/ut-api'
  import grids from '@/plugins/ut/ut-grids'

  export default {
    components: { GridWrapper },
    data() {
      return {
        fetching: false,
        taskItems: [],
        selectedTasks: [],
        rowActions: [
          {
            icon: 'mdi-delete',
            handler: ({ data }) => this.onDelete([data]),
          },
        ],
      }
    },
    computed: {
      tasksColumnDefs: () => grids.getTasksColumnDefs(),
      noDataMessage: ({ taskItems, $i18n }) =>
        taskItems.length > 0 ? $i18n.t('no_filtered_data_tasks') : $i18n.t('no_data_defined_tasks'),

      // returns selected task
      selectedTask: ({ selectedTasks }) => selectedTasks[0] || undefined,
    },

    created() {
      this.getTaskList()
    },

    methods: {
      // fetch tasks
      async getTaskList() {
        this.fetching = true
        const response = await api.cloud('Untangle_CommandCenter', 'getTasks')
        if (response.success && response.data) {
          this.taskItems = response.data
        }
        this.fetching = false
      },

      // delete tasks
      onDelete(tasks) {
        this.$vuntangle.confirm.show({
          title: this.$t('remove_task'),
          message: this.$t('confirm_remove_task'),
          action: async resolve => {
            const selectedTaskIds = tasks.map(task => task.TaskId)
            const response = await api.cloud('Untangle_CommandCenter', 'removeTasks', {
              selectedTasks: selectedTaskIds,
              paramOrder: 'selectedTasks',
            })
            if (response.success && !response.message) {
              this.$vuntangle.toast.add(this.$t('tasks_remove_successful'))
              // remove the deleted tasks from the tasks array
              this.taskItems = this.taskItems.filter(item => !selectedTaskIds.includes(item.TaskId))
              await this.getTaskList()
            } else {
              this.$vuntangle.toast.add(
                this.$t(this.$te(`${response.message}`) ? `${response.message}` : 'tasks_remove_failed'),
                'error',
              )
            }
            this.$refs.wrapper.toggleSelection()
            resolve()
          },
        })
      },
    },
  }
</script>
