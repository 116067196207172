<template>
  <div class="d-flex flex-column flex-grow-1 flex-basis-0 mb-4">
    <grid-layout
      :layout.sync="baseLayout"
      :responsive-layouts.sync="responsiveLayouts"
      :row-height="300"
      :margin="[16, 16]"
      :use-css-transforms="false"
      :is-resizable="false"
      :is-draggable="false"
      vertical-compact
      responsive
      :breakpoints="$vuntangle.widgets.breakpoints"
      :cols="$vuntangle.widgets.gridSizes"
      class="mb-n4"
    >
      <grid-item
        v-for="widget in baseLayout"
        :key="widget.i"
        :x="widget.x"
        :y="widget.y"
        :w="widget.w"
        :h="widget.h"
        :i="widget.i"
        drag-allow-from=".drag-handle"
        @resized="onResizedWidget(widget.i)"
        @moved="storeLayout(baseLayout)"
      >
        <component
          :is="widget.cmp"
          :appliances="appliances"
          :resize.sync="widget.resize"
          :uids="uids"
          :policies="policies"
          :account-status="accountStatus"
          @remove="onRemove(widget.id)"
          @refresh="fetch(true)"
        />
      </grid-item>
    </grid-layout>
  </div>
</template>
<script>
  import { GridLayout, GridItem } from 'vue-grid-layout'
  import { Type } from 'vuntangle/pm'
  import { getPolicyManagerAppliances } from '../util'
  import {
    AlertHistory,
    AuditHistory,
    ApplianceMap,
    MfwAppliances,
    MfwInfo,
    AssignmentsStatus,
    PolicyAnalytics,
    WidgetsOverviewMixin,
  } from '@/components/widgets'
  import api from '@/plugins/ut/ut-api'

  const widgetComponentMap = {
    general_information: { component: 'MfwInfo', width: 1 },
    assignments_status: { component: 'AssignmentsStatus', width: 1 },
    appliances_map: { component: 'ApplianceMap', width: 1 },
    mfw_appliances: { component: 'MfwAppliances', width: 3 },
    policy_analytics: { component: 'PolicyAnalytics', width: 3 },
    alert_history: { component: 'AlertHistory', width: 2 },
    audit_history: { component: 'AuditHistory', width: 1 },
  }

  export default {
    components: {
      AlertHistory,
      AuditHistory,
      ApplianceMap,
      MfwAppliances,
      MfwInfo,
      AssignmentsStatus,
      PolicyAnalytics,

      GridLayout,
      GridItem,
    },
    mixins: [WidgetsOverviewMixin],
    data() {
      return {
        fetching: false,
        selection: [],
        baseLayout: [],
        responsiveLayouts: {},
        accountStatus: {},
      }
    },
    computed: {
      appliances: () => getPolicyManagerAppliances(),
      uids: ({ appliances }) => appliances.map(app => app.Uid),
      policies: ({ $store }) => $store.getters['policyManager/getObjectsByType'](Type.Policy),
    },
    async mounted() {
      // settings for WidgetsOverviewMixin
      this.widgetComponentMap = widgetComponentMap
      this.storedLayoutName = 'policy-manager-overview-layout'
      this.updateLayoutPath = 'data/UPDATE_POLICY_MANAGER_OVERVIEW_WIDGETS'
      this.getWidgetsPath = 'data/policyManagerOverviewWidgets'
      this.fetch(false)
      this.setLayout()

      // set the user info widget data
      const response = await api.cloud('Untangle_CommandCenter', 'GetAggregateAccountStatus')
      if (response.success && response.data) this.accountStatus = response.data
      else {
        this.accountStatus.user = this.$t('no_data')
      }
    },
    methods: {
      fetch(force) {
        this.$store.dispatch('appliances/fetchFullAppliances', { force })
        this.$store.dispatch('policyManager/fetchObjectsByType', { type: Type.Policy, force })
        this.$store.dispatch('policyManager/getAllApplianceAssignments', { force })
        this.$store.dispatch('policyManager/fetchDependencyMap', { force })
      },
    },
  }
</script>
