<template>
  <u-widget
    class="full-height"
    :title="$t('appliance_policies')"
    :info="$t('info_appliance_policies')"
    enable-refresh
    @refresh="fetchData(true)"
    v-on="$listeners"
  >
    <div v-if="showUpgradeLayout" class="appliance-policies-upgrade">
      {{ $t('mfw_upgrade_51') }}
    </div>
    <div :class="`${selectedTab === 'policies' ? 'd-flex' : 'd-none'} fill-height`">
      <u-grid
        id="policies"
        row-node-id="id"
        :no-data-message="$t('no_data')"
        :column-defs="columnDefPolicies"
        :fetching="fetching"
        :row-data="policies"
        :framework-components="frameworkComponents"
        @row-clicked="rowClickedPolicies"
        @refresh="fetchData(true)"
      />
    </div>
    <div :class="`${selectedTab === 'rules' ? 'd-flex' : 'd-none'} fill-height`">
      <u-grid
        id="rules"
        row-node-id="id"
        :no-data-message="$t('no_data')"
        :column-defs="columnDefRules"
        :fetching="fetching"
        :row-data="rules"
        :framework-components="frameworkComponents"
        @row-clicked="rowClickedRules"
        @refresh="fetchData(true)"
      />
    </div>
    <v-bottom-navigation v-model="selectedTab" height="40" class="align-center elevation-0 ma-0" color="green">
      <v-btn v-for="(tab, i) in tabs" :key="i" :value="tab.value">
        <span class="font-weight-bold">{{ $t(tab.text) }}</span>
      </v-btn>
    </v-bottom-navigation>
  </u-widget>
</template>
<script>
  import {
    columnDefs,
    ConditionsRenderer,
    ConditionGroupsRenderer,
    ActionRenderer,
    AssociatedPolicyRenderer,
    NameRenderer,
  } from 'vuntangle/pm'
  import { hydrateAppliancePoliciesData, hydrateRulesData } from '@/pages/policies/mfw/policy-manager/hydration'
  import util from '@/plugins/ut/ut-util'
  import renderersMixin from '@/pages/policies/mfw/policy-manager/renderersMixin'

  export default {
    mixins: [renderersMixin],
    data: () => ({
      fetching: false,
      frameworkComponents: {
        ConditionsRenderer,
        ConditionGroupsRenderer,
        ActionRenderer,
        AssociatedPolicyRenderer,
        NameRenderer,
      },
    }),
    computed: {
      appliance: ({ $store, $route }) => $store.getters['appliances/getByUniqueIdentifier']($route.params.id),
      policiesObjects: ({ $store }) => $store.getters['policyManager/getAllPoliciesForAppliance'] || [],
      rulesObjects: ({ $store }) => $store.getters['policyManager/getAllRulesForAppliance'] || [],

      /** Returns formatted policies for current appliance */
      policies: ({ policiesObjects, showUpgradeLayout }) =>
        hydrateAppliancePoliciesData(showUpgradeLayout ? [] : policiesObjects),
      /** Returns formatted rules for current appliance */
      rules: ({ rulesObjects, policiesObjects }) => hydrateRulesData(rulesObjects, policiesObjects),

      columnDefPolicies: () => columnDefs.getAppliancePoliciesColumnDefs(),
      columnDefRules: ({ augmentColumns }) =>
        augmentColumns(columnDefs.getApplianceRulesColumnDefs(), ['action', 'policies']),

      tabs() {
        return [
          { text: 'policies', value: 'policies' },
          { text: 'rules', value: 'rules' },
        ]
      },
      selectedTab: {
        get() {
          return this.$store.state.appliancePoliciesSelectedTab
        },
        set(value) {
          this.$store.commit('SET_APPLIANCE_POLICIES_SELECTED_TAB', value)
        },
      },
      // appliance version is lower than 5.1
      showUpgradeLayout: ({ appliance }) => util.compareVersions(appliance.SoftwareVersion, '5.1') === -1,
    },
    beforeMount() {
      if (!this.showUpgradeLayout) this.fetchData()
    },
    methods: {
      /**
       * dispatch actions to fetch policies, conditions, condition groups and appliance policies
       */
      async fetchData(force) {
        this.fetching = true
        await Promise.allSettled([
          // fetch all objects used for rendering Conditions column
          this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-object', force }),
          this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-config', force }),
          this.$store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-policy', force }),
          this.$store.dispatch('policyManager/getDataForAppliancePoliciesWidget', this.appliance.Uid),
          this.$store.dispatch('policyManager/fetchDependencyMap', { force }),
        ])

        this.fetching = false
      },

      /** routes to the second step of assignment */
      rowClickedPolicies() {
        this.$router.push({ name: 'pm-provisioning', params: { selectedAppliances: [this.appliance] } })
      },

      /** routes to the edit rule page */
      rowClickedRules({ node }) {
        this.$router.push({ name: 'pm-rules-rule', params: { ruleId: node.data.id } })
      },
    },
  }
</script>

<style>
  .appliance-policies-upgrade {
    position: absolute;
    opacity: 0.9;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    z-index: 1;
  }

  .ut-dark-body .appliance-policies-upgrade {
    background-color: #1f2427;
  }
</style>
