<!--
  EditPolicy.vue - router component used for the main entry point when editing the policy
  This contains just Name/Description and navigation to subroutes
  - PolicyConditions.vue - to edit global policy conditions
  - PolicyRules.vue - to edit rules for each of the Policy Types (security etc...)
-->
<template>
  <div v-if="policy" class="d-flex flex-grow-1 flex-basis-0">
    <!-- navigation with policy types -->
    <v-navigation-drawer
      :expand-on-hover="false"
      :mini-variant="mini"
      mini-variant-width="48"
      permanent
      width="300"
      class="flex-grow-0 flex-shrink-0"
      :color="!mini || $vuetify.theme.dark ? 'transparent' : 'grey lighten-3'"
      @transitionend="onTransitionEnd"
    >
      <v-fade-transition v-if="!mini">
        <div v-show="!mini && transitioned" class="pa-4">
          <div class="d-flex align-start">
            <h1 v-if="policy.Name" class="headline">{{ policy.Name }}</h1>
            <h1 v-else class="headline text--disabled">{{ $t('new') }}</h1>
            <v-spacer />
            <v-btn fab x-small elevation="0" @click.stop="onMiniToggle">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>

          <p class="caption mt-2">{{ policy.Description }}</p>

          <v-btn
            color="primary"
            class="text-capitalize flex-grow-1"
            depressed
            :disabled="!isChanged"
            @click="onSavePolicy"
          >
            <v-icon small class="mr-2">mdi-content-save</v-icon> {{ $t('save') }}
          </v-btn>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <u-btn
                class="mx-2"
                :small="false"
                :min-width="56"
                :disabled="isNewPolicy"
                v-bind="attrs"
                @click="onDownloadPolicy"
                v-on="on"
              >
                <v-icon>mdi-download</v-icon>
              </u-btn>
            </template>
            <span>{{ $t('export') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <u-btn
                color="error"
                :small="false"
                :min-width="56"
                :disabled="isNewPolicy"
                data-testid="delete-pm-entity"
                v-bind="attrs"
                @click="onDeletePolicy"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </u-btn>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>

          <v-divider class="my-4" />

          <v-list class="list-dense pa-0" nav dense>
            <v-list-item
              :class="{ 'v-list-item--active primary--text': $route.name === 'pm-policy-conditions' }"
              @click="$router.push({ name: 'pm-policy-conditions' })"
            >
              <v-list-item-title>{{ $t('conditions') }} ({{ conditionsCount }})</v-list-item-title>
            </v-list-item>

            <h4 class="my-2">{{ $t('rules') }}</h4>

            <div v-for="(items, key) in navItems" :key="key">
              <v-subheader v-if="key !== 'none'">{{ $t(key) }}</v-subheader>
              <div :class="key !== 'none' ? 'ml-4' : ''">
                <v-list-item
                  v-for="item in items"
                  :key="item.key"
                  dense
                  :class="{ 'v-list-item--active primary--text': $route.params.ruleType === item.key }"
                  @click="
                    $router.push({
                      name: 'pm-policy-rules',
                      params: { ruleType: item.key },
                    })
                  "
                >
                  <v-list-item-title>
                    {{ $t(item.text) }}
                    <span class="font-weight-regular lighten-3">({{ item.count }})</span>
                  </v-list-item-title>
                </v-list-item>
              </div>
            </div>
          </v-list>
        </div>
      </v-fade-transition>

      <div v-if="mini" class="d-flex flex-column pa-2">
        <v-tooltip right transition="none" color="primary">
          <template #activator="{ on, attrs }">
            <v-btn fab x-small elevation="0" class="mb-2" v-bind="attrs" v-on="on" @click.stop="onMiniToggle">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <div>
            <h1 class="headline">{{ policy.Name || editPolicy.Name }}</h1>
            <p class="caption ma-0">{{ policy.Description || editPolicy.Description }}</p>
          </div>
        </v-tooltip>

        <v-tooltip right transition="none" color="primary">
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              elevation="0"
              color="primary"
              class="mb-2"
              v-bind="attrs"
              :disabled="!isChanged"
              v-on="on"
              @click="onSavePolicy"
            >
              <v-icon small>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('save') }}</span>
        </v-tooltip>

        <v-tooltip right transition="none" color="primary">
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              elevation="0"
              color="primary"
              class="mb-2"
              v-bind="attrs"
              :disabled="isNewPolicy"
              v-on="on"
              @click="onDownloadPolicy"
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('export') }}</span>
        </v-tooltip>

        <v-tooltip right transition="none" color="primary">
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              elevation="0"
              color="error"
              v-bind="attrs"
              :disabled="isNewPolicy"
              v-on="on"
              @click="onDeletePolicy"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('delete') }}</span>
        </v-tooltip>

        <v-divider class="my-4" />

        <v-tooltip right transition="none" color="primary">
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              elevation="3"
              :color="$route.name === 'pm-policy-conditions' ? 'primary' : ''"
              v-bind="attrs"
              v-on="on"
              @click="$router.push({ name: 'pm-policy-conditions' })"
            >
              <v-icon small>mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('conditions') }} ({{ conditionsCount }})</span>
        </v-tooltip>

        <v-divider class="my-4" />

        <div v-for="(items, key) in navItems" :key="key" class="d-flex flex-column">
          <v-tooltip v-for="item in items" :key="item.key" right transition="none" color="primary">
            <template #activator="{ on, attrs }">
              <v-btn
                fab
                x-small
                elevation="3"
                :color="$route.params.ruleType === item.key ? 'primary' : ''"
                v-bind="attrs"
                class="my-1"
                v-on="on"
                @click="
                  $router.push({
                    name: 'pm-policy-rules',
                    params: { ruleType: item.key },
                  })
                "
              >
                {{ item.avatar }}
              </v-btn>
            </template>
            <span>{{ $t(item.text) }} ({{ item.count }})</span>
          </v-tooltip>
        </div>
      </div>
    </v-navigation-drawer>

    <!-- the router viehat handles the above navigation -->
    <div class="d-flex flex-grow-1 overflow-auto">
      <router-view ref="view" />
    </div>
  </div>
</template>
<script>
  import isEqual from 'lodash/isEqual'
  import cloneDeep from 'lodash/cloneDeep'
  import { rulesConfig, Type } from 'vuntangle/pm'
  import { VFadeTransition } from 'vuetify/lib'
  import { deleteMFWObjects } from '../util'
  import i18n from '@/plugins/vue-i18n'

  export default {
    components: { VFadeTransition },
    /**
     * Provide conditions stats down to `ConditionsEditor` component which updates the
     * `conditionsStats.changed` prop based on modifications made
     * This permits checking if one or multiple policy condition objects have changed values
     */
    provide() {
      return {
        $conditionsStats: () => this.conditionsStats,
      }
    },
    /**
     * Checks if we have a policy for the provided id. Else, redirect to main policies grid
     *
     * @param to
     * @param from
     * @param next
     * @returns {Promise<void>}
     */
    beforeRouteEnter(to, from, next) {
      next(({ $router, $store, $vuntangle }) => {
        const policyId = to.params.policyId
        const foundPolicy = $store.getters['policyManager/getEditObjectById'](policyId)
        // check if we found a policy to edit. If not, redirect back to main policies grid
        if (!foundPolicy) {
          $vuntangle.toast.add(i18n.t('no_record_found'), 'error')
          $router.push({ name: 'pm-policies' })
        }
      })
    },

    /**
     * Display a confirmation dialog when attempting to leave page with unsaved policy changes
     * There are three actions available:
     * - Cancel (oe ESC) - to remain in the policy editing page
     * - Discard - to navigate away without saving
     * - Save - to save policy changes and after that it routes to desired location
     */
    beforeRouteLeave(to, from, next) {
      if (this.beforeUnload) {
        window.removeEventListener('beforeunload', this.beforeUnload)
      }
      // only show dialog if the policy was changed
      if (!this.isChanged) {
        next()
        return
      }
      const vm = this
      this.$vuntangle.confirm.show({
        title: this.$t('save_policy_changes'),
        message: this.$t('save_policy_changes_text'),
        buttons: [
          // cancel button - stay on page
          {
            name: this.$t('cancel'),
            props: {
              minWidth: null,
              small: false,
              text: true,
              depressed: true,
              class: 'text-capitalize',
            },
            handler() {
              this.onClose()
            },
          },
          // discard button, leave page
          {
            name: this.$t('discard'),
            props: {
              minWidth: null,
              small: false,
              color: 'error',
              depressed: true,
              class: 'text-capitalize px-4',
            },
            handler() {
              this.onClose()
              next()
            },
          },
          // save button, saves changes and leaves page after
          {
            name: this.$t('save'),
            props: {
              minWidth: null,
              small: false,
              depressed: true,
              class: 'text-capitalize px-4',
            },
            async handler() {
              await vm.onSavePolicy()
              this.onClose()
              next(to)
            },
          },
        ],
      })
    },

    data() {
      return {
        mini: false,
        transitioned: true,
        conditionsStats: {
          changed: false,
        },
      }
    },

    computed: {
      id: ({ $route }) => $route.params.policyId,
      ruleType: ({ $route }) => $route.params.ruleType,
      // in case of new policy we want the `policy` object to be an empty object in order for the comparison
      // to always return !== and always be savable
      policy: ({ $store, id }) => $store.getters['policyManager/getObjectById'](id) || {},
      isNewPolicy: ({ editPolicy }) => editPolicy?.Id === Type.Policy,
      editPolicy: ({ $store, id }) => $store.getters['policyManager/getEditObjectById'](id),

      conditionsCount: ({ editPolicy }) => editPolicy?.PolicyJson.conditions?.length || 0,

      /** navigation items for each rule type */
      navItems: ({ editPolicy }) => {
        if (!editPolicy) return
        const groups = {}
        Object.entries(rulesConfig).forEach(([key, config]) => {
          if (!groups[config.category]) groups[config.category] = []
          const count = editPolicy.PolicyJson.rules[key]?.length || 0
          groups[config.category].push({
            key,
            avatar: config.avatar,
            text: config.text,
            to: 'pm-policy-rules',
            count,
          })
        })
        /** CD-4403 - alpha sort rule types with respect to their translations */
        for (const item in groups) {
          groups[item].sort((a, b) => i18n.t(a.text).localeCompare(i18n.t(b.text)))
        }
        return groups
      },

      /**
       * Upon rule order manually changed, it enables/disables the Update button
       */
      isChanged: ({ policy, editPolicy, conditionsStats }) => {
        // if not create and policy is empty that means it got deleted
        if (editPolicy?.Id !== editPolicy?.Type && Object.keys(policy).length === 0) {
          // so don't show unsaved changes warning
          return false
        }
        return !!editPolicy && (!isEqual(policy, editPolicy) || conditionsStats.changed)
      },
    },

    mounted() {
      // add `beforeunload` event
      window.addEventListener('beforeunload', this.beforeUnload)
    },

    methods: {
      /** shows browser native confirmation dialog when refreshing the page */
      beforeUnload(event) {
        if (this.isChanged) {
          event.preventDefault()
          // both preventDefault() and returnValue = '' are responsible for showing the popup when closing the window
          // depending on which browser is being used
          event.returnValue = ''
        }
      },

      /**
       * Dispatches action to save the Policy to the cloud
       */
      async onSavePolicy() {
        // if policy name not set and not in conditions view where it can be edited, just redirect there
        if (!this.editPolicy.Name && this.$route.name !== 'pm-policy-conditions') {
          this.$router.push({ name: 'pm-policy-conditions' })
          return
        }

        // validate name where in conditions view where Name field exists
        if (this.$refs.view.validateName) {
          const isValidName = await this.$refs.view.validateName()
          if (!isValidName) return
        }

        this.$store.commit('SET_PAGE_LOADER', true)

        // save conditions first if in conditions view and changed
        if (this.$route.name === 'pm-policy-conditions' && this.conditionsStats.changed) {
          const conditionsIds = await this.$refs.view.savePolicyConditions()
          if (conditionsIds) {
            this.editPolicy.PolicyJson.conditions = conditionsIds
            this.conditionsStats.changed = false
          } else {
            this.$store.commit('SET_PAGE_LOADER', false)
            return
          }
        }

        let object = {}
        // delete the Id in order for BE to save the policy and not try to update
        if (this.isNewPolicy) {
          object = cloneDeep(this.editPolicy)
          object.Id = ''
        } else {
          object = this.editPolicy
        }
        const response = await this.$store.dispatch('policyManager/saveObject', { object })
        this.$store.commit('SET_PAGE_LOADER', false)

        if (response) {
          this.$vuntangle.toast.add(this.$t('saved_successfully', [this.$t('policy')]))
          this.$store.commit('policyManager/SET_EDIT_OBJECT', response)
          // if new policy was saved, redirect to proper page using generated Id
          if (this.isNewPolicy) this.$router.push({ name: 'pm-policy-conditions', params: { policyId: response.Id } })
          return true
        } else {
          this.$vuntangle.toast.add(this.$t('unable_to_save', [this.$t('global.policy')]), 'error')
          return false
        }
      },

      /**
       * Link to download the policy JSON that will be pushed to the appliance.
       */
      onDownloadPolicy() {
        window.location.href = `${process.env.VUE_APP_API_URL || ''}/downloadPolicy/${encodeURIComponent(this.id)}`
      },

      /**
       * shows confirmation dialog and on cofirm dispatches the action to delete policy from db
       *
       * @param {Object} object object to be deleted
       */
      onDeletePolicy() {
        deleteMFWObjects([this.policy], true, response => {
          if (response.data?.deleted) {
            this.$router.push({ name: 'pm-policies' })
          }
        })
      },

      onMiniToggle() {
        this.mini = !this.mini
        this.transitioned = false
      },

      onTransitionEnd() {
        this.transitioned = true
      },
    },
  }
</script>
