<template>
  <v-dialog v-model="show" persistent transition="fade-transition" width="80%">
    <v-sheet class="d-flex overflow-auto" height="90vh">
      <component
        :is="settingsComponent"
        v-if="configurationCopy"
        ref="component"
        :show-rules="false"
        :show-custom-rule-tab="false"
        :settings="configurationCopy.PolicyJson"
        :applications="applications"
        :leases="false"
        :features="features"
        classic-view
      >
        <template #extra-fields>
          <ValidationObserver ref="extraFields">
            <!-- name and description fields -->
            <name-description :name.sync="configurationCopy.Name" :description.sync="configurationCopy.Description" />
          </ValidationObserver>
        </template>
        <template #actions="{ newSettings }">
          <v-btn text class="text-capitalize mr-2" @click="closeConfigurationDialog">{{ $t('cancel') }}</v-btn>
          <v-btn color="primary" class="text-capitalize" depressed @click="onSaveSetting(newSettings)">
            <v-icon small class="mr-2">mdi-content-save</v-icon>
            {{ $t('save') }}
          </v-btn>
        </template>
      </component>
    </v-sheet>
    <v-overlay v-model="fetching" absolute class="text-center">
      <v-progress-circular indeterminate size="32" color="aristaMediumBlue"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import {
    SettingsGeoipFilter,
    SettingsThreatPrevention,
    SettingsWebFilter,
    SettingsWanPolicies,
    SettingsApplicationControl,
    SettingsCaptivePortal,
  } from 'vuntangle'
  import { templatesConfig } from 'vuntangle/pm'
  import NameDescription from './NameDescription.vue'

  export default {
    components: {
      SettingsGeoipFilter,
      SettingsThreatPrevention,
      SettingsWebFilter,
      SettingsWanPolicies,
      SettingsApplicationControl,
      SettingsCaptivePortal,
      NameDescription,
    },
    data() {
      return {
        configurationCopy: undefined,
        fetching: false,
        features: { isTemplateView: true },
      }
    },

    computed: {
      ...mapState('policyManager', ['configurationDialog']),
      ...mapGetters('data', ['applications']),

      configuration: ({ configurationDialog }) => configurationDialog.configuration,

      config: ({ configuration }) => templatesConfig[configuration?.Type],
      settingsComponent: ({ config }) => config?.settingsComponent,

      // dialog is shown when configuration object is set
      show: ({ configuration }) => !!configuration,
    },

    watch: {
      configuration: {
        handler(value) {
          // set local copy of object for manipulation
          this.configurationCopy = cloneDeep(value)
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions('policyManager', ['closeConfigurationDialog', 'saveObject']),

      /**
       * Dispatches action to save object settings
       * @param {Object} settingObj - the edited settings to be saved
       */
      async onSaveSetting(settingObj) {
        // validate name and description fields
        const valid = await this.$refs.extraFields.validate()
        // validate component if has extra validation
        const componentValid = this.$refs.component.validate ? await this.$refs.component.validate() : true
        if (!valid || !componentValid) {
          return
        }

        // populate PolicyJson with the configuration settings
        this.configurationCopy.PolicyJson = settingObj

        // dispatch action to save the template configuration
        this.fetching = true
        const response = await this.saveObject({ object: this.configurationCopy })
        this.fetching = false

        /**
         * If configuration object successfully created
         * it gets added to the Rule action if thats the context of editor
         */
        if (response) {
          this.$vuntangle.toast.add(this.$t('saved_successfully', [this.$t('configuration')]))
          if (this.configurationDialog.cb) this.configurationDialog.cb(response)
        } else {
          this.$vuntangle.toast.add(this.$t('unable_to_save', [this.$t('configuration')]), 'error')
        }
        this.closeConfigurationDialog()
      },
    },
  }
</script>
