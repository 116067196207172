<template>
  <div class="d-flex flex-column flex-grow-1">
    <v-tabs v-model="activeTab" class="flex-grow-0 align-center">
      <v-tab v-for="tab in tabs" :key="tab.text" class="text-capitalize" @click="$router.push(tab.to)">
        {{ $t(tab.text) }}
      </v-tab>
      <v-spacer />
      <v-btn rounded depressed class="align-self-center primary--text mr-2" @click="showTasks()">
        <v-icon class="mr-2">mdi-order-bool-ascending-variant</v-icon>
        <span class="text-capitalize">{{ $t('tasks') }}</span>
      </v-btn>
      <v-btn
        v-if="outOfSyncAppliances"
        rounded
        color="warning"
        depressed
        class="align-self-center primary--text mr-4"
        @click="$router.push({ name: 'pm-assignment' })"
      >
        <v-icon class="mr-2">mdi-sync-alert</v-icon>
        <span class="primary--text text-lowercase">{{ $t('appliances_out_of_sync', [outOfSyncAppliances]) }}</span>
      </v-btn>
    </v-tabs>
    <v-divider />
    <router-view class="overflow-auto" />
    <!-- dialog used for editing objects/groups -->
    <edit-object-dialog />
    <!-- dialog used for editing configurations -->
    <edit-configuration-dialog />
  </div>
</template>
<script>
  import { Type } from 'vuntangle/pm'
  import { generateDefaultMFWObject } from './util'
  import EditObjectDialog from './components/EditObjectDialog.vue'
  import EditConfigurationDialog from './components/EditConfigurationDialog.vue'
  import TasksGrid from '@/components/grids/TasksGrid'
  import store from '@/store'

  export default {
    components: { EditObjectDialog, EditConfigurationDialog },
    /**
     * fetch some initial data when accessing policy manager
     * Policies and Condition objects are mostly used
     * Appliances are fetched for Overview page
     */
    async beforeRouteEnter(to, from, next) {
      store.commit('SET_PAGE_LOADER', true)
      await Promise.allSettled([
        store.dispatch('policyManager/fetchDependencyMap', {}),
        store.dispatch('policyManager/fetchObjectsByType', { type: Type.Policy }),
        store.dispatch('policyManager/fetchObjectsByPrefix', { prefix: 'mfw-object' }),
        store.dispatch('data/fetchApplications'),
        store.dispatch('policyManager/getUnsyncedAppliancesCount'),
      ])
      store.commit('SET_PAGE_LOADER', false)

      /**
       * If route params like policyId, ruleId, configId etc.
       * exists then fetch data for the IDs
       *
       * Note: Right now this method only allows users to directly land
       * on one level of depth, e.g. /:ruleId would be allowed
       * but directly going to :ruleId/:conditionId/:objectId will redirect you to :ruleId
       */
      next(async ({ $router, $store }) => {
        /**
         * map of where to redirect users to against a certain route param
         * Note: order of properties in this map matters
         * e.g. if ruleId and conditionId both exists
         * then we want user to be redirected to rule page
         * so `ruleId` key should come first
         */
        const redirectMap = {
          policyId: 'pm-policy-conditions',
          ruleId: 'pm-rules-rule',
          configurationId: 'pm-templates-configuration',
          conditionId: 'pm-objects-condition',
          objectId: 'pm-object',
        }
        // filtered list of route params in current route
        const routeParams = Object.keys(redirectMap).filter(paramName => to.params[paramName])

        if (!routeParams.length) {
          return
        }

        // if route params exists we are only interested in the top most param
        const paramName = routeParams[0]
        const mfwId = to.params[paramName]

        const getFromCache = $store.getters['policyManager/getEditObjectById']
        const getFromStore = $store.getters['policyManager/getObjectById']
        // if object doesn't exist in editor cache then push it in
        if (!getFromCache(mfwId)) {
          // if mfwId equals one of mfw type, then it means user is on add form
          if (Object.values(Type).find(type => type === mfwId)) {
            // push the default mfw object into editor cache
            $store.commit('policyManager/SET_EDIT_OBJECT', generateDefaultMFWObject(mfwId))
          } else {
            // fetch object against ID either from store or from server
            $store.commit('SET_PAGE_LOADER', true)
            const mfwObject = getFromStore(mfwId) || (await $store.dispatch('policyManager/fetchObjectById', mfwId))
            $store.commit('SET_PAGE_LOADER', false)
            if (mfwObject) {
              $store.commit('policyManager/SET_EDIT_OBJECT', mfwObject)
            }
          }
        }
        // if more than one params exists at the same time,
        // then redirect to the top most route
        if (routeParams.length > 1) {
          const params = {
            [paramName]: mfwId,
            ...(to.params.objectType ? { objectType: to.params.objectType } : {}),
          }
          return $router.push({ name: redirectMap[paramName], params })
        }
      })
    },
    data() {
      return {
        activeTab: 0,
      }
    },
    computed: {
      tabs: ({ $store }) => [
        { text: 'overview', to: { name: 'pm-overview' } },
        { text: 'policies', to: { name: 'pm-policies' } },
        { text: 'rules', to: { name: 'pm-rules' } },
        { text: 'objects', to: { name: 'pm-objects' } },
        { text: 'templates', to: { name: 'pm-templates' } },
        { text: 'assignment', to: { name: 'pm-assignment' } },
        ...($store.state.data.ccViewModel.MfwPolicies?.PolicyManagerReports
          ? [{ text: 'reports', to: { name: 'pm-reports' } }]
          : []),
      ],
      outOfSyncAppliances: ({ $store }) => $store.getters['policyManager/getOutOfsyncCount'],
      // resolve the full paths of the tab links for selecting the active tab
      tabLinks: ({ tabs, $router }) => tabs.map(tab => $router.resolve(tab.to).resolved.fullPath),
    },
    watch: {
      // update the activeTab used in the v-model for v-tabs. Uses the index of the tab
      '$route': {
        immediate: true,
        handler() {
          let tabIndex = this.tabs.findIndex(tab => this.$route.matched.some(match => match.name === tab.to.name))
          // if -1, it wasn't found in the matched, check the subroutes of the fullPath
          if (tabIndex === -1) {
            tabIndex = this.tabLinks.findIndex(tabLink =>
              // check the fullPath for the resolved path
              this.$route.fullPath.startsWith(tabLink + '/'),
            )
          }
          this.activeTab = tabIndex !== -1 ? tabIndex : 0
        },
      },
    },

    methods: {
      showTasks() {
        this.$vuntangle.dialog.show({
          title: this.$t('tasks'),
          edge: true,
          width: '90vw',
          height: '100%', // the height of the inner dialog content
          buttons: [
            {
              name: this.$t('close'),
              handler() {
                this.onClose()
              },
            },
          ],
          component: TasksGrid,
        })
      },
    },
  }
</script>
