/**
 * Methods used to hydrate grid data as expected by columns definitions
 * Each data keys (id, name, description...) represents the fields used in column defs
 */

import { ConditionTarget, ruleType, templateType } from 'vuntangle/pm'
import {
  ruleActionValue,
  objectValue,
  associatedAppliancesValue,
  associatedRuleAppliancesValue,
  associatedPolicyValue,
  conditionsValue,
  conditionGroupsValue,
} from './valueGetters'

import vuntangle from '@/plugins/vuntangle'

/**
 * Hydrates policies grid
 * @param {Array} policies - policies objects
 * @returns Array
 */
function hydratePoliciesData(policies) {
  return policies.map(policy => ({
    id: policy.Id,
    name: policy.Name,
    description: policy.Description,
    source: conditionsValue(policy.PolicyJson.conditions, ConditionTarget.Source),
    dest: conditionsValue(policy.PolicyJson.conditions, ConditionTarget.Destination),
    other: conditionsValue(policy.PolicyJson.conditions, ConditionTarget.Other),
    group: conditionGroupsValue(policy.PolicyJson.conditions),
    created: vuntangle.dates.formatDateFromApi(policy.DateCreated),
    updated: vuntangle.dates.formatDateFromApi(policy.DateUpdated),
    appliances: associatedAppliancesValue(policy.Id),
  }))
}

/**
 * Hydrates Appliance Policies widget data
 * @param {Array} policies - policies objects
 * @returns Array
 */
function hydrateAppliancePoliciesData(policies) {
  return policies.map(policy => ({
    id: policy.Id,
    name: policy.Name,
    description: policy.Description,
    source: conditionsValue(policy.PolicyJson.conditions, ConditionTarget.Source),
    dest: conditionsValue(policy.PolicyJson.conditions, ConditionTarget.Destination),
    other: conditionsValue(policy.PolicyJson.conditions, ConditionTarget.Other),
    group: conditionGroupsValue(policy.PolicyJson.conditions),
    created: vuntangle.dates.formatDateFromApi(policy.DateCreated),
    updated: vuntangle.dates.formatDateFromApi(policy.DateUpdated),
    order: policy.Ordering,
    lastSynced: policy.LastSynced,
    syncStatus: policy.SyncStatus,
  }))
}

/**
 * Hydrates Rules grid
 * @param {Array} rules - rules objects
 * @param {Object} policies - policies used to check if a rule is disabled or not (optional)
 * @returns Array
 */
function hydrateRulesData(rules, policies) {
  return rules.map(rule => ({
    id: rule.Id,
    // `enabled` prop is added only in policy context, not generic Rules listing
    ...(policies && { enabled: policies.some(policy => !policy.PolicyJson.disabledRules?.includes(rule.Id)) }),
    name: rule.Name,
    description: rule.Description,
    type: ruleType(rule.Type),
    source: conditionsValue(rule.PolicyJson.conditions, ConditionTarget.Source),
    dest: conditionsValue(rule.PolicyJson.conditions, ConditionTarget.Destination),
    other: conditionsValue(rule.PolicyJson.conditions, ConditionTarget.Other),
    group: conditionGroupsValue(rule.PolicyJson.conditions),
    action: ruleActionValue(rule.PolicyJson.action),
    policies: associatedPolicyValue(rule.Id),
    appliances: associatedRuleAppliancesValue(rule.Id),
    created: vuntangle.dates.formatDateFromApi(rule.DateCreated),
    updated: vuntangle.dates.formatDateFromApi(rule.DateUpdated),
  }))
}

/**
 * Hydrates Conditions grid
 * @param {Array} conditions - conditions objects
 * @returns Array
 */
function hydrateConditionsData(conditions) {
  return conditions.map(cnd => ({
    id: cnd.Id,
    name: cnd.Name,
    description: cnd.Description,
    source: conditionsValue([cnd.Id], ConditionTarget.Source),
    dest: conditionsValue([cnd.Id], ConditionTarget.Destination),
    other: conditionsValue([cnd.Id], ConditionTarget.Other),
    group: conditionGroupsValue([cnd.Id]),
    created: vuntangle.dates.formatDateFromApi(cnd.DateCreated),
    updated: vuntangle.dates.formatDateFromApi(cnd.DateUpdated),
    policies: associatedPolicyValue(cnd.Id),
  }))
}

/**
 * Hydrates Templates grid
 * @param {Array} templates - templates objects
 * @returns Array
 */
function hydrateTemplatesData(templates) {
  return templates.map(tpl => ({
    id: tpl.Id,
    name: tpl.Name,
    description: tpl.Description,
    type: templateType(tpl.Type),
    created: vuntangle.dates.formatDateFromApi(tpl.DateCreated),
    updated: vuntangle.dates.formatDateFromApi(tpl.DateUpdated),
    policies: associatedPolicyValue(tpl.Id),
    appliances: associatedAppliancesValue(tpl.Id),
  }))
}

/**
 * Hydrates Objects grid
 * @param {Array} objects
 * @returns Array
 */
function hydrateObjectsData(objects) {
  return objects.map(obj => ({
    id: obj.Id,
    name: obj.Name,
    description: obj.Description,
    created: vuntangle.dates.formatDateFromApi(obj.DateCreated),
    updated: vuntangle.dates.formatDateFromApi(obj.DateUpdated),
    value: objectValue(obj),
  }))
}

export {
  hydratePoliciesData,
  hydrateAppliancePoliciesData,
  hydrateRulesData,
  hydrateConditionsData,
  hydrateTemplatesData,
  hydrateObjectsData,
}
