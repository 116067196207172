<template>
  <not-compatible v-if="notCompatible" :feature-name="serviceConfig.tkey" />
  <div v-else>
    <component
      :is="component.name"
      v-if="boxSettings"
      ref="component"
      :settings="boxSettings[boxPath]"
      :interfaces="boxSettings.network.interfaces"
      :features="appliance.features"
      :classic-view="true"
      v-bind="component.props"
      :disabled="readOnly"
      v-on="component.events"
      @refresh="onRefresh"
      @run-collector="onRunCollector"
      @fetch-routing-table="onFetchRoutingTable"
    >
      <template #actions="{ newSettings, isDirty, defaults }">
        <u-btn v-if="showCacheClear" class="mr-2" @click="clearCache">{{ $t('clear_cache') }}</u-btn>
        <!-- CD-4664 show reset defaults only if component passes those defaults -->
        <u-btn v-if="defaults" class="mr-2" @click="onReset(defaults)">
          {{ $t('reset_to_defaults') }}
        </u-btn>
        <u-btn :min-width="null" :disabled="!isDirty" @click="onSave(newSettings)">{{ $t('save') }}</u-btn>
      </template>
    </component>
  </div>
</template>
<script>
  import {
    SettingsApplicationControl,
    Dhcp,
    Dns,
    SettingsGeoipFilter,
    SettingsNetworkDiscovery,
    SettingsThreatPrevention,
    SettingsWebFilter,
    SettingsCaptivePortal,
    SettingsDenialOfService,
    SettingsDnsFilter,
    StaticRoutes,
  } from 'vuntangle'
  import { NotCompatible } from '../layout'
  import { setComponent } from './settingsComponent'
  import settingsMixin from './settingsMixin'
  import { mfwServices } from '@/util/mfwServices'
  import appliance from '@/plugins/ut/ut-appliances'
  import api from '@/plugins/ut/ut-api'

  export default {
    components: {
      SettingsApplicationControl,
      Dhcp,
      Dns,
      SettingsGeoipFilter,
      SettingsNetworkDiscovery,
      SettingsThreatPrevention,
      SettingsWebFilter,
      SettingsCaptivePortal,
      SettingsDenialOfService,
      SettingsDnsFilter,
      StaticRoutes,
      NotCompatible,
    },
    mixins: [settingsMixin],
    data() {
      return {
        component: null, // the component to be used
        preFetchCompleted: false,
      }
    },
    computed: {
      settingsPath: ({ $route }) => $route.params.settings,
      serviceConfig: ({ settingsPath }) => mfwServices[settingsPath],
      boxPath: ({ serviceConfig }) => serviceConfig?.settingPathForBox,
      showCacheClear: ({ serviceConfig }) => serviceConfig?.showCacheClear,
      // boolean telling if this feature is avaibale for the appliance based on version
      notCompatible: ({ appliance, serviceConfig }) => parseFloat(appliance?.SoftwareVersion) < serviceConfig?.version,
    },

    async beforeMount() {
      /** initializes the component along with settingsPath and appliance Uid */
      this.component = setComponent(this.settingsPath, this.appliance.Uid)

      /** if settingsPath is wrong
       * or no appliance settings,
       * or security license is required
       * redirect to appliance dashboard
       */
      if (!this.component || !this.boxSettings || this.securityLicenseRequired()) {
        this.$router.push({ name: 'appliances-id' })
        return
      }

      // fetches the actual settings
      if (this.component.preFetch) {
        this.$store.commit('SET_PAGE_LOADER', true)
        // fetches extra data needed for component (e.g. applications, leases)
        await this.component.preFetch()
        this.$store.commit('SET_PAGE_LOADER', false)
      }

      this.preFetchCompleted = true
    },

    methods: {
      /**
       * check if appliance has a valid security subscription if the service requires one
       * @returns {Boolean} true if securityLicense required
       */
      securityLicenseRequired() {
        // if the account is not license enforced, security license is not required
        if (this.$store.state.data.ccViewModel.Account.NoLicenseEnforcement) return false
        let isBasicEdition = true
        this.appliance.Subscriptions.forEach(subscription => {
          const sub = this.$store.state.subscriptions.list.find(
            sub => sub.subscriptionName === subscription.subscriptionName,
          )
          if (sub.sku.includes('SWSE')) {
            isBasicEdition = false
          }
        })
        return isBasicEdition && mfwServices[this.settingsPath].securityLicenseRequired
      },

      /**
       * returns the IP addresses of the interfaces
       * @returns {Array} IP address
       */
      async getIps() {
        const interfaces = await this.getFromAppliance(`status/interfaces/all`)
        const ips = []
        for (const value of Object.values(interfaces.data)) {
          if (!value.wan) {
            for (const [ipAddr, ipsArray] of Object.entries(value)) {
              if (['ip4Addr', 'ip6Addr'].includes(ipAddr) && ipsArray != null) {
                ipsArray.forEach(value => {
                  ips.push(value)
                })
              }
            }
          }
        }
        return ips
      },

      /**
       * Run Sync
       */
      async onRunCollector(pluginName) {
        const ips = await this.getIps()
        const response = await this.saveSettingsAPI('collectors/call', {
          ip: ips,
          collectors: [pluginName],
        })
        if (response.success) {
          this.$vuntangle.toast.add(this.$t('run_successfully', [this.$t('network_discovery')]))
        }
      },

      /**
       * calls the mixin `saveSettings` method
       * @param {Object|Array} settings - the settings as updated in shared component
       */
      async onSave(settings) {
        // validate name and description fields
        const componentValid = this.$refs.component.validate ? await this.$refs.component.validate() : true
        if (!componentValid) {
          return
        }

        // preSave runs any checks and anything else that needs saving besides what the general save does,
        // should return a Promise<boolean> that can be checked
        if (this.component.preSave) {
          this.$store.commit('SET_PAGE_LOADER', true)
          const result = await this.component.preSave(settings)
          if (!result) {
            this.$store.commit('SET_PAGE_LOADER', false)
            this.$vuntangle.toast.add(this.$t('settings_update_failed'), 'error')

            return
          }
        }

        this.saveSettings(this.boxPath, settings)
      },

      /**
       *  Clear cache
       */
      async clearCache() {
        this.$store.commit('SET_PAGE_LOADER', true)
        const response = await appliance.sendToApplianceApi(this.appliance.Uid, `${this.boxPath}/cacheclear`)
        this.$store.commit('SET_PAGE_LOADER', false)

        if (response.success) {
          this.$vuntangle.toast.add(this.$t('cache_cleared_successfully', [this.$t('cache_clear')]))
        } else {
          this.$vuntangle.toast.add(this.$t('an_error_occurred'), 'error')
        }
      },

      /**
       * Shows a confirmation window when resetting to defaults
       * @param defaults
       */
      onReset(defaults) {
        this.$vuntangle.confirm.show({
          title: this.$t('confirm'),
          message: this.$t('services_reset_warning'),
          action: resolve => {
            this.onSave(defaults)
            resolve()
          },
        })
      },

      /**
       * Refreshes box settings
       * @param defaults
       */
      onRefresh() {
        this.fetchSettings()
      },

      /**
       * Fetch routing table data from box. Pass result to component
       * @param resolve
       */
      async onFetchRoutingTable(resolve) {
        this.$store.commit('SET_PAGE_LOADER', true)

        // call backend to sync the settings to cmd
        const response = await api.cloud('Untangle_CommandCenter', 'FetchRoutingTable', {
          uid: this.uid,
          paramOrder: 'uid',
        })

        this.$store.commit('SET_PAGE_LOADER', false)

        resolve(response.success ? response.data : [])
      },
    },
  }
</script>
